import './assets/css/App.css';
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home"
import ResultsPage from "./pages/ResultsPage"
import DetailsPage from "./pages/DetailsPage"
import SavedPage from "./pages/SavedPage"
import FollowingPage from "./pages/FollowingPage"
import ShoppingList from "./pages/ShoppingList"
import PasswordReset from "./pages/PasswordReset"

import Meta from './components/Meta';
import CookieMessage from './components/CookieMejsxssage';
import { useSelector, useDispatch } from "react-redux";
import { updateAccountInfo } from './actions/index'
import { useEffect } from 'react'
import Login from './components/Login';
import FollowingConflict from './components/FollowingConflict';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
const testApi = async () => {
  // axios.get("https://elasticsearch.100procentlokaal.be/");
  const response = await axios.get("https://elasticsearch.100procentlokaal.be/_cat/indices?v", {
    auth: {
      username: "ashish",
      password: "QWi7RhJmByGuVmmnZng4",
    },
  });
  console.log(response);
  return response;
}

const App = () => {
  const { translate, lang } = useSelector(state => state.general);
  const { loginScreen, openFollowingConflict } = useSelector(state => state.account);
  const dispatch = useDispatch()

  let page_slug = translate('page').toLowerCase()

  testApi();

  useEffect(() => {
    getData()
  }, [])
  const getData = () => {
    if (localStorage.getItem("promotoken")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("promotoken")}`,
      };
      axios.get(`https://management.100procentlokaal.be/core/api/catalogus/user/?lang=${lang}`, {
        headers: headers,
      })
        .then((res) => {
          let copy = res.data
          const today = new Date(); // current date
          const filteredArray = copy.saved_cmps.filter(item => {
            // Parse date strings in "DD-MM-YYYY" format
            const parseDate = dateString => {
              const [day, month, year] = dateString.split('-').map(Number);
              return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Dates
            };

            const visibleEnd = parseDate(item.visible_end.substr(0, 10));

            // Calculate two weeks after visible_end
            const twoWeeksAfterEnd = new Date(visibleEnd);
            twoWeeksAfterEnd.setDate(visibleEnd.getDate() + 14);

            // Check if today is within two weeks after visible_end
            return today <= twoWeeksAfterEnd;
          });
          copy.saved_cmps = filteredArray

          dispatch(updateAccountInfo(res.data))
        })
    }
  }

  const createNotification = (type, message) => {
    if (type === "info") {
      NotificationManager.info(message);
    } else if (type === "success") {
      NotificationManager.success(message);
    } else if (type === "warning") {
      NotificationManager.warning(message);
    } else if (type === "error") {
      NotificationManager.error(message);
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Meta />
        <CookieMessage />
        <NotificationContainer />
        {loginScreen && <Login createNotification={createNotification} />}
        {openFollowingConflict && <FollowingConflict createNotification={createNotification} />}
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} createNotification={createNotification} />} />
          <Route path={`/${translate("search_slug")}/:keyword?/${page_slug}=:page?/`} render={(props) => <ResultsPage {...props} createNotification={createNotification} />} />
          <Route path="/business/:id?" render={(props) => <DetailsPage {...props} createNotification={createNotification} getData={getData} />} />
          <Route path="/saves/" render={(props) => <SavedPage {...props} createNotification={createNotification} />} />
          <Route path="/following/" render={(props) => <FollowingPage {...props} createNotification={createNotification} />} />
          <Route path="/shoppinglist/" render={(props) => <ShoppingList {...props} createNotification={createNotification} />} />
          <Route path="/reset_password/:token/" render={(props) => <PasswordReset {...props} createNotification={createNotification} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
